  /**** font stuff ****/
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=PT+Serif:400,400i,700,700i&display=swap');


  /* global styles */

  .inset-paragraph-container h1 sup {
    font-size:55%;
  }

  /***** Hero Image styles *****/
  .hero-img{
    width: 100%;
    display: block;
    height: auto;
    background-size: cover;
  }

  .hero-img-text{
    position: relative;
  }

  .hero-img-overlay{
    /*height: 100px;*/
    height: auto;
    position: absolute;
    bottom: 0px;
    width:100%;
    text-align: center;
    /*opacity: .8;*/
    /*padding-top:5px;*/
  }
  .hero-img-overlay p{
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    font-style: italic;
    padding-top: 15px;
  }
  .hero-img-overlay h2{
    font-size: 24px;
    color: #fff;
  }

  .hero-heading-text{
    font-size: 36px !important;
    line-height: 50px;
    font-weight: bold;
    font-family: 'Open Sans' !important;
  }

  .hero-img-overlay-mid{
    height: 100px;
    position: absolute;
    bottom: 40%;
    width:100%;
    text-align: center;
    /*opacity: .8;*/
    text-shadow: none;
  }

  .hero-img-overlay-mid h2{
    font-size: 36px;
    line-height: 100px;
    color: #fff;
    font-family: 'Open Sans' !important;
    font-weight: bold;
  }

/*****************************/

.img-background{
  background: #cecece;
  background: url(/images/Fresh_cows_gradient_1920x615.webp);
  background-position: center center;
  background-size: auto;
  background-repeat: no-repeat;
  max-height: 525px;
  max-width: 100%;
}


/****** Image overlay styles ******/
.img-overlay{
  height: 75px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  /*opacity: .8;*/
  text-shadow: none;
}

.img-overlay h2{
  color: #fff;
  /*vertical-align: middle;*/
  margin-bottom: 15px;
  margin-top: 15px;
  line-height: 45px;
  font-family: 'Open Sans' !important;
  transition: transform .2s; /* Animation */
}

.img-overlay:hover{
  background: #0055a5 !important;
}

.apply-hover-effect:hover{
  opacity: .85;
  /*transform: scale(1.05);*/
}

.leftParagraphRightImage{
  padding: 10px;
}

.leftParagraphRightImage.inner{
  padding: 50px 150px;
}

.leftParagraphRightImageHeading{
  padding:50px 150px 10px;
}

.textCallout{
  padding:10px 150px;
}



/**********************************/

/********* marketing feature **********/
.marketing-feature{
  padding-right: 100px !important;
}

.marketing-feature h1{
  color: #0055a5;
  font-size: 36px;
  font-family: 'PT Serif' !important;
}

.marketing-feature h2{
  color: #4e4e4e;
  font-size: 24px;
  font-family: 'Open Sans' !important;
  font-weight: 700;
}
.marketing-feature p{
  color: #4e4e4e;
  font-size: 14px;
}
.inline{
  display: inline-flex;
}
.feature-row{
  margin: 10px 0;
}
.feature-row:hover{
  transform: scale(1.05);
}
.feature-row a:hover {
  text-decoration:none;
}

.marketing-feature h4{
  font-family: 'Open Sans';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: #4e4e4e;
  margin: 45px 0 0 15px;
  text-align: left;
  line-height: 24px;
}
/*********************/

.outer{
  position:fixed;
}



/*  centered overlay */
.centered-overlay {
  /*margin: 75px 30%;*/
  /*opacity: .8;*/
  /* renamed this class to .imageWithTextOverlay-overlay
  since no longer centered by default */
}
.imageWithTextOverlay-overlay {
  text-shadow: none;
  position: absolute;
  top: 20%;                 /* added top, left and width */
  left: 25%;
  min-width: 40%;
  max-width: 50%;
  background: rgba(0,85,165,.7);
}
.imageWithTextOverlay-overlay.overlay-position-left {
  left:7%;
}
.imageWithTextOverlay-overlay.overlay-position-right {
  left:auto;
  right:7%;
}
.imageWithTextOverlay-overlay.overlay-position-center {
  left:25% !important;
  top:30% !important;
}
@media (min-width:1000px) {
  .imageWithTextOverlay-overlay-overlayWidth-narrow {
    padding-left:109px!important;
    padding-right:58px!important;
  }
}

.imageWithTextOverlay-overlay.height-short {
  max-width: 56%;
  top: 8%;
  left: 38%;;
}
@media (max-width:1250px) {
  .imageWithTextOverlay-overlay.height-short {
    top:5%;
  }
}

.centered-overlay-text{
  padding: 25px 25px;
}

.imageWithTextOverlay-overlay.height-short .centered-overlay-text {
  padding-bottom:0;

}
@media (max-width:1250px) {
  .imageWithTextOverlay-overlay.height-short .centered-overlay-text {

    padding-top:10px;
  }
}
.centered-overlay h2{
  font-size: 28px;
  font-family: 'Open Sans' !important;
  font-weight: bold;
  border-bottom: 2px #fdb813 solid;
  display: inline-block;
  padding-bottom: 10px;
}
.centered-overlay p{
  font-size: 18px;
}
.imageWithTextOverlay-overlay.height-short .centered-overlay-text p {
  font-size:15px!important;
}
@media (max-width:1250px) {
  .imageWithTextOverlay-overlay.height-short .centered-overlay-text p {
    margin-bottom:0;
    padding-bottom:0;
  }
}

.default-button, .btn.btn-primary{
  border: 0px solid rgb(0,42,69);
  /*background: #fdb813;*/
  background: rgba(253,184,19,1);
  color: rgb(0,42,69);
  padding: 10px 20px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;   /* changed from aqualine */
  font-size: 18px;
  margin-right: 30px;
}
.default-button:hover, .btn.btn-primary:hover{
  text-decoration: none;
  color: #fff;
}

.alert{
  margin-bottom: 0px;
}

.alert > .default-button{
  border: 0px solid rgb(0,42,69);
  background: rgba(253,184,19,1);
  color: rgb(0,42,69);
  padding: 10px 20px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;   /* changed from aqualine */
  font-size: 18px;
}
/*********************/

.text-container{
  margin: 75px 75px;
}
.feature-solutions h2{
  font-size: 36px;
  font-family: 'PT Serif', serif !important;
}
.feature-solutions p{
  font-size: 18px;
  font-family: 'Open Sans';
  color: #4e4e4e;
}
.form{
  background: #e5e5e5;
}

.form h2{
  color: #4e4e4e;
  font-family: 'Open Sans' !important;
}

.contact-form input[type="text"], .contact-form input[type="email"], .contact-form textarea{
  width: 100%;
  padding: 10px 10px;
  margin: 10px 0;
}

.contact-form button{
  width: 75%;
  padding: 10px 10px;
  margin: 10px 0;
  background: #fdb813;
  color: #000;
}

.contact-form button:hover{
  color: #fff;
}



#frmResume input[type="file"]{
  position: absolute;
  /* z-index: -1; */
  border: 0px solid rgb(0,42,69);
  /*background: #fdb813;*/
  /* background: rgba(253,184,19,1); */
  color: rgb(0,42,69);
}

.blockTestimonial .line{
  width:200px;
}

.blockTestimonial .testimonial-name{
  color: #0055a5;
  font-size:1.5rem;
  font-weight: 700;
  text-align: center;
}

.blockTestimonial h2{
  color: #0055a5;
  font-family: 'PT Serif',serif !important;
  font-weight: 500;
}

.blockTestimonial .testimonial-name span{
  
  font-size:1.5rem;
  font-weight: 400;
  font-style: italic;
}


blockquote {
	font-weight: 100;
	font-size: 2rem;
	max-width: 600px;
	line-height: 1.4;
	position: relative;
	margin: 0;
	padding: .5rem;
}

blockquote:before,
blockquote:after {
	position: absolute;
	color: #fdb813;
	font-size: 6rem;
	width: 4rem;
	height: 4rem;
  font-weight: bold;
  font-family: serif;
}

blockquote:before {
	content: '\201C';
	left: -30px;
	top: -1rem;
}

blockquote:after {
	/*content: '”';*/
  content:'\201D';
	right: -45px;
	top: -1rem;
}

cite {
	line-height: 3;
	text-align: left;
}





@media (max-width: 767px) {
  .freeform-row {
    /* background: red; */
    display: block! important; /* flex only used for 50% width columns, unneeded */
  }
}


.four-icon h3{
  font-family: 'Open Sans' !important;
}

.four-icon p{
  font-family: 'Open Sans';
  color: #9c9c9c;
  font-size: 16px;
  max-width: 85%;
  text-align: center;
  margin: 0 auto;
}

.four-icon-link:hover{
  text-decoration: none;
}

.four-icon-link img:hover{
  /*-webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;*/
}

.product-icons h2{
  font-size: 24px;
  padding: 20px 0;
  color: #4e4e4e;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif !important;
}

.product-icons a:hover{
  text-decoration: none;
}

.product-icons a img:hover{
  /*-webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  */
}

.benefit-section{
  background: rgb(243,243,243);
}

.benefit-section h3{
  font-family: 'Open Sans', sans-serif !important;
  color: #4e4e4e;
  font-size: 28px;
  font-weight: 600;
}

.benefit-section h2{
  color: #355784 !important;
  font-family: 'PT Serif', sans-serif !important;
  font-size: 36px;
}

.benefit-section p{
  font-size: 18px;
  color: #4e4e4e;
  font-family: 'Open Sans' sans-serif;
}

.card{      /* this overrides card css */
  border-radius: 0 !important;
  background: #5e93ca !important;
  border: 1px solid #f3f3f3 !important;
  width:75%;
  /*height:200px;*/
}

.card .btn{
  border: 0px solid #f3f3f3;
  border-radius: 0px;
}

.card-header{
  padding: 5px !important;
  margin-bottom: 0 !important;
}

.card-header:hover{
  /*background:#eaeef4 !important;*/
  /*background: rgb(114,160,208) !important;*/
  background: #355784;
  cursor: pointer;
}

.card-body{
  /*background: #d6e1ee;*/
  background: #e5e5e5;
  padding: 10px 10px !important;
}

.card-body:hover{
  background: #f3f3f3;
  /*background: #fff;*/
}

#fi-contact-listing.card-body{
  max-height: 225px;
  overflow: scroll;
}

.card i{
  font-size:14px;
}

.btn-link{
  color:#fff !important;
  padding: 0 0 !important;
}

.formula-request{
  padding: 50px 50px;
}

.formula-request h3{
  font-size: 18px;
  color: #4e4e4e;
  font-weight: 700;
}

.formula-request .form-group{
  margin-bottom: 0px;
}

#formulaRequest button{
  margin-top: 1rem;
}

.formula-request-list{
  padding: 20px;
  text-align: center;
  color: #4e4e4e;
}

.formula-request-list:hover{
  cursor: pointer;
}

/**************** news-events ******************/
.news-events h1{
  color:#0055a5;
}

.news-events h2, .news-events h3{
  color: #4e4e4e;
}

.news-events a{
  text-decoration: none;
  font-size: 18px;
  color: #335784;
  border-bottom: 2px solid rgb(192,192,192);
}

.news-events a:hover{
  text-decoration: none;
  color: #223a59;
}

.threeCol{
  color: #787878;
}

.chart-legend { float:right; }
.chart-legend h4{ text-align:center; }

.chart-legend ul{ text-align: left; display:inline; }


/***********************************************/

/*  download links  */
.downloads{
  list-style-type: none;
}
.download-links{
  text-decoration: none;
  color: #0055a5;
}

/***********************************************/


/*********  table formatting *******************/
.table-header{
  background:#0084C8;
  width:100%;
  padding:10px 0;
}

.table-header h2{
  color: #fff;
}
/***********************************************/

/*   utilities   */
.inset-container{
  /*margin: 50px 100px;*/
  padding: 50px 100px;
  display: flex;
}

.inset-header-container{
  margin: 25px 12.5%;
}

.inset-header-container h2{
  margin-bottom:0;
}

.inset-paragraph-container{
  margin: 10px 12.5%;
}

.inset-paragraph-container h2{
  font-family: 'Open Sans', sans-serif !important;
  font-weight:700;
}

.inset-paragraph-container h4{
  text-align: center;
}

.form-inset{
  margin: 50px 100px;
}

.spacer{
  padding-bottom: 12px;
}

.show-me{

}

.errors{
  color:red;
}

/*************************************************/

/* horizontal line */
.line{
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #fdb813;
  margin: 1em 0;
  padding: 0;
  width: 50%;
}

.centered-line{
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #fdb813;
  margin: auto;
  padding: 0;
  width: 25%;
}

/* vertical line */
/* remove if not being used */
.vertical-line{
  display: block;
  border: 0;
  border-right: 2px solid #fdb813;
  margin: 1em 0;
  padding: 0;
}

/* horizontal line */
.v-line{
  border-right: 2px solid #fdb813;
  height: 140px;
}

.center-image{
  text-align: center;
  margin: 10% 0;
}

/* this may override .img-fluid */
.fit-image{
  object-fit: cover;
  max-width: 100% !important;
  min-height: 100% !important;
}

.clearfix{
  clear:both;
}

.default-pad{
  height:28px;
}

.active{
  font-weight: bold;
}

label.required::after {
  content: " " !important;
}

.vertical-center{
  margin-top:15%;
}

.slider-overlay{
  width:60%;
}

/*****************/

/*** main elements ***/
body{
  width: 1366px;
  margin: 0 auto !important;

  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

p {
  font-family: 'Open Sans';
  color:#4e4e4e;
  font-size: 18px;
}

iframe{
  width: 100%;
}

h2{
  font-family: 'PT Serif', serif !important;
}

h3{
  font-family: 'PT Serif', serif !important;
}

a{
  color: #0055a5;
}

#my-list{
  list-style-type: none;
}

#my-list li i{
  font-size: 12px
}

#my-list li:hover{
  background: white;
}

#submitWhitePaper{

}

.format-file-select{

}

/*********************/


/* swinging effect */
@-webkit-keyframes swing
{
    15%
    {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30%
    {
        -webkit-transform: translateX(-5px);
       transform: translateX(-5px);
    }
    50%
    {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65%
    {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80%
    {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes swing
{
    15%
    {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30%
    {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }
    50%
    {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65%
    {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80%
    {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fade-in {
	opacity:0;  /* make things invisible upon start */
	-webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
	-moz-animation:fadeIn ease-in 1;
	animation:fadeIn ease-in 1;

	-webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
	-moz-animation-fill-mode:forwards;
	animation-fill-mode:forwards;

	-webkit-animation-duration:1s;
	-moz-animation-duration:1s;
	animation-duration:1s;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
